function request (options) {
  if (typeof options === 'string') {
    options = {
      url: options
    }
  }
  return new Promise((resolve, reject) => {
    $.ajax({
      ...options,
      success (json) {
        resolve(json)
      },
      error (err, textStatus, errorThrown) {
        if (typeof err === 'object') {
          reject(Object.assign(err, { textStatus, errorThrown }))
        } else {
          reject(err)
        }
      }
    })
  })
}
export default request
