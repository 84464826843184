<template>
  <!-- 推荐位 -->
  <div
    v-show="!batchActive"
    ref="recommend-wrapper-container"
    v-infinite-scroll="infiniteScroll"
    class="recommend-wrapper not-fsp-element"
    style="min-height: 1px;"
    :infinite-scroll-distance="infiniteScrollDistance"
    infinite-scroll-nodata="noMoreData"
  >
    <!-- banner位置 -->
    <template v-if="!loginStatus">
      <CartEmptyBannerContainer
        v-show="hasFetchCart && carts.length == 0"
        :show-banner="showBanner"
      />
    </template>
    <ClientOnly>
      <RecommendCccNew
        v-if="showReccomendBlockReload && hasFetchCart && showAppCcc"
        :extend-params="extendParams"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { debounce } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import CartEmptyBannerContainer from '../vue_tpls/CartEmptyBannerContainer.vue'


export default {
  name: 'RecommendWrapper',
  components: {
    CartEmptyBannerContainer,
    RecommendCccNew: () => import(/* webpackChunkName: "cart-recommend-new" */'./recommend-ccc.vue'),
  },
  props: {
    hasFetchCart: {
      type: Boolean,
      default: false
    },
    shouldReload: {
      type: Boolean,
      default: false
    },
    needRender: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showReccomendBlockReload: false, // 是否展示推荐位模块
      showRecommendGap: false,
      recommendObserver: null,
      firstRender: false,
      maxLength: 0,
      showAppCcc: null,
    }
  },
  computed: {
    ...mapGetters('cart', ['carts']),
    ...mapState('cart', ['language', 'loginStatus', 'batchActive', 'cartInfo', 'locals', 'cartAbtInfo']),
    // 推荐位需要过滤的商品id
    filterData () {
      const goodsIds = []
      const cateIds = []
      if (this.carts && this.carts.length) {
        this.carts.forEach(item => {
          if (item.product) {
            goodsIds.push(item.product.goods_id)
            cateIds.push(item.product.cat_id)
          }
        })
      }
      return {
        goodsIds,
        cateIds
      }
    },
    extendParams() {
      return {
        cate_ids: this.filterData.cateIds.join(','),
        goods_ids: this.filterData.goodsIds.join(',')
      }
    },
    showBanner () {
      return this.cartAbtInfo.BannerDistrictimage?.param?.BannerDistrictShow === 'Show'
    },
    infiniteScrollDistance() {
      return typeof window !== 'undefined'
        ? Math.floor(window.innerWidth * 0.75 * 5) // 滚动预加载距离
        : 10000
    },
    noMoreData() {
      return this.renderCount >= this.maxLength
    }
  },
  watch: {
    shouldReload (val) {
      if (val) {
        this.handleCCCRecReload()
      }
    },
    carts (n, o) {
      this.handleRecommendInit(n, o)
    }
  },
  mounted () {
    this.getShowAppCccStatus()
    if(this.carts?.length === 0 || this.needRender) {
      this.handleCCCRecReload()
    }
  },
  methods: {
    async getShowAppCccStatus() {
      const { componentswitch } = await getUserAbtData()
      this.showAppCcc = componentswitch?.param?.shopbagrecstyle === '1'
    },
    toRender() {
      if(this.showReccomendBlockReload) return
      this.handleCCCRecReload()
    },
    handleRecommendInit (n, o) {
      const emptyToHaveItems = n && o && o.length === 0 && n.length > 0 // 空 => 非空
      const haveItemsToEmpty = n && o && n.length === 0 && o.length > 0 // 非空 => 空
      // 空 -> 有 || 有 -> 空
      // 购物车空与非空切换临界值，需要对推荐进行重载
      if (emptyToHaveItems || haveItemsToEmpty) {
        this.handleCCCRecReload(true)
      }
    },
    changeReload (flag) {
      // this.showReccomendBlockReload = flag
      if (flag) {
        this.handleCCCRecReload()
      }
    },
    handleCCCRecReload: debounce({ func: function (toTop = false) {
      if(this.firstRender) return
      this.showReccomendBlockReload = false
      this.showRecommendGap = false
      this.$nextTick(() => {
        // 购物车状态切换回到购物车顶部
        toTop && window.scrollTo({ top: 0, behavior: 'smooth' })
        this.showReccomendBlockReload = true
        this.$emit('reload-success')
      })
    }, wait: 300 }),
    // ccc 推荐组件
    onCccRecommendListReady (payload) {
      this.maxLength = payload.list.length
      this.showRecommendGap = !!payload.list.length
    },
    infiniteScroll(){
      let compoName =  'cartRecommendCcc'
      if(!this.firstRender && this.$refs[`${compoName}`]?.recommendUiGroup?.[0]?.dataInstance?.product?.config?.renderCount){
        this.$refs[`${compoName}`].recommendUiGroup[0].dataInstance.product.config.renderCount =  ++this.renderCount
      }
    }
  }
}
</script>
