<template>
  <component
    :is="componentName"
    v-if="showBanner"
    :show-banner="showBanner"
    v-bind="componentBind"
  />
</template>

<script>
import { mapState } from 'vuex'
import CartEmptyBanner from './CartEmptyBanner.vue'
import CartAppCcc from './CartAppCcc.vue'

export default {
  components: {
    CartEmptyBanner,
    CartAppCcc,
  },
  props: {
    showBanner: Boolean,
  },
  data() {
    return {
      placeHolderKey: 'empty_cart_above_rec', // 空车页推荐位之上
      componentName: '',
      componentBind: {},
    }
  },
  computed: {
    ...mapState('cart', ['cartBannerSsrData', 'cartAppCccBannerSsrData']),
  },
  created() {
    // 没有 空车页推荐位之上 的 banner 数据，则走老组件逻辑
    if (this.hasEmptyCartAboveData(this.cartAppCccBannerSsrData)) {
      this.componentBind = {
        cartBannerSsrData: this.cartAppCccBannerSsrData,
        placeHolderKey: this.placeHolderKey
      }
      this.componentName = 'CartAppCcc'
    } else {
      this.componentBind = {
        cartBannerSsrData: this.cartBannerSsrData
      }
      this.componentName = 'CartEmptyBanner'
    }
  },
  methods: {
    /**
     * 判断是否有空车推荐位之上的 banner 数据
     */
    hasEmptyCartAboveData(appCccData) {
      if (!Array.isArray(appCccData?.content)) return false
      const target = appCccData.content.find(f => f.placeHolderKey === this.placeHolderKey)
      return !!target
    },
  }
}
</script>
