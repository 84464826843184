<template>
  <AppCcc
    v-if="content && content.length > 0"
    :content="content"
    :context="context"
    :scene-data="sceneData"
    :cate-links="cateLinks"
  />
</template>

<script>
import AppCcc from '@/public/src/pages/components/ccc/Index.vue'

const { WEB_CLIENT } = gbCommonInfo

export default {
  components: {
    AppCcc,
  },
  props: {
    cartBannerSsrData: {
      type: Object,
      default: () => null,
    },
    /**
     * 展位标识
     * 当有值时，则只渲染当前展位标识的组件
     */
    placeHolderKey: {
      type: String,
      default: '',
    }
  },
  computed: {
    content() {
      let res = this.cartBannerSsrData?.content || []
      if (this.placeHolderKey) {
        res = res.filter(f => f.placeHolderKey === this.placeHolderKey)
      }
      return res
    },
    context() {
      return {
        WEB_CLIENT
      }
    },
    sceneData() {
      const { pageType = '', id = '' } = this.cartBannerSsrData || {}
      return {
        pageFrom: pageType,
        sceneName: pageType,
        pageType: pageType,
        id,
      }
    },
    cateLinks() {
      return this.cartBannerSsrData?.cateLinks || {}
    },
  }
}
</script>
