<template>
  <div
    class="empty-cart-container mt-40"
  >
    <s-button
      v-expose="{
        id: '1-8-1-16',
        data: {
          go_to_page: goToPageType
        }
      }" 
      v-tap="{
        id: '1-8-1-15',
        data: {
          go_to_page: goToPageType
        }
      }"
      :type="['primary', 'H72PX']"
      @click="handleToShop"
    >
      {{ language.SHEIN_KEY_PWA_15659 }}
    </s-button>
  </div>
</template>

<script>
import { Button } from '@shein/sui-mobile'
import { mapState } from 'vuex'
import { EMPTY_CART_PAGE_FROM } from '../utils/constants'
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { markPoint } from 'public/src/services/mark/index.js'

const { langPath } = gbCommonInfo

export default {
  name: 'EmptyCartOperate',
  components: {
    SButton: Button
  },
  computed: {
    ...mapState('cart', ['language']),
    goToPageType () {
      return 'shopnow'
    }
  },
  methods: {
    handleToShop() {
      markPoint('toNextPageClick', 'public')
      this.$routerPush(transformPromodiscountUrl(`${langPath}/shein-picks.html?ici=${EMPTY_CART_PAGE_FROM}`))
    }
  }
}
</script>

<style lang="less">
.empty-cart-container {
  text-align: center;
  .flexbox();
  .align-center();
  flex-direction: column;
  &.mt-40 {
    margin-top: 40/75rem;
  }
}
</style>
