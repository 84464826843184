var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"empty-cart-container mt-40"},[_c('s-button',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-8-1-16',
      data: {
        go_to_page: _vm.goToPageType
      }
    }),expression:"{\n      id: '1-8-1-16',\n      data: {\n        go_to_page: goToPageType\n      }\n    }"},{name:"tap",rawName:"v-tap",value:({
      id: '1-8-1-15',
      data: {
        go_to_page: _vm.goToPageType
      }
    }),expression:"{\n      id: '1-8-1-15',\n      data: {\n        go_to_page: goToPageType\n      }\n    }"}],attrs:{"type":['primary', 'H72PX']},on:{"click":_vm.handleToShop}},[_vm._v("\n    "+_vm._s(_vm.language.SHEIN_KEY_PWA_15659)+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }