<template>
  <div
    v-if="cartBannerData.content && cartBannerData.content.content && showEmptyBanner"
    class="j-cart-banner cart-empty-banner"
    :style="{height:bannerHeight}"
  >
    <template v-for="(itemData, index) in propData">
      <CccCartBanner
        v-if="isClient && (beginTime <= currentTime && endTime >= currentTime || isPreview)"
        :key="itemData.oper_id + index"
        :index="index + 1"
        :context="resContent"
        :prop-data="itemData"
        :cate-info="cateInfo"
        :style="{ height:bannerHeight }"
      />
    </template>
  </div>
</template>
<script>
import { handleImageJump } from 'public/src/pages/common/ccc_public'
import request from '../../product_app/request'
import { abtservice } from 'public/src/services/abt'
import { stringifyQueryString, parseQueryString } from '@shein/common-function'
const { langPath } = gbCommonInfo

export default {
  name: 'EmptyBanner',
  components: {
    CccCartBanner: () => import('./CccCartBanner.vue')
  },
  props: {
    showBanner: {
      type: Boolean,
      default: false
    },
    cartBannerSsrData: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      propData: [],
      currentTime: 0,
      cateInfo: {},
      itemCateType: {},
      isPreview: false,
      resContent: {}, // Banner返回的数据
      lang: '',
      site: '',
      aod_id: '0',
      aodAbtData: {},
      advert_scene_key: 'mobile_cart',
      cartBannerData: {},
      endTime: 0,
      beginTime: 0,
      bannerHeight: 0,
      showEmptyBanner: false,
    }
  },
  computed: {
    id () {
      return this.propData[0] && this.propData[0]['content'] && this.propData[0]['content']['id']
    },
    isClient(){
      return typeof window != 'undefined'
    }
  },
  watch: {
    cateInfo: function () {
      Object.keys(this.itemCateType).forEach(function (type) {
        if (this.cateInfo[type]) {
          this.itemCateType[type] = this.cateInfo[type]
        }
      }.bind(this))
    }
  },
  created: function () {
    this.initData()
  },
  methods: {
    async fetchData () {
      const { content_ids, tag, advert_scene_key } = parseQueryString(location.search) // 预览
      // eslint-disable-next-line @shein-aidc/abt/abt
      const abtResult = await abtservice.getUserAbtResult({ posKeys: 'CccEmptyCart', newPosKeys: 'BannerDistrictimage' })
      const abtBranch = abtResult['CccEmptyCart'] && abtResult['CccEmptyCart'].param || ''
      this.showEmptyBanner = abtResult?.BannerDistrictimage?.param?.BannerDistrictShow === 'Show'

      const _search = {
        abtBranch,
        tag
      }
      if (content_ids && advert_scene_key == this.advert_scene_key) {
        _search.advert_scene_key = advert_scene_key
        _search.content_ids = content_ids
      } else {
        _search.advert_scene_key = this.advert_scene_key
      }
      const _url = `${langPath}/api/ccc/configBanner/get?${stringifyQueryString({ queryObj: _search })}`
      return request(_url)
    },
    initData: async function () {
      const _this = this
      this.showEmptyBanner = this.cartBannerSsrData ? this.showBanner : false
      const cartBannerData = this.cartBannerSsrData ? this.cartBannerSsrData : await this.fetchData() || {}
      this.cartBannerData = cartBannerData
      if (!(cartBannerData.content && cartBannerData.content.content)) {
        return
      }
      // var cartBannerData = opt.cartBannerData
      this.isPreview = cartBannerData.isPreview
      this.aodAbtData = cartBannerData.aodAbtData
      this.site = cartBannerData.site
      this.lang = cartBannerData.lang
      this.endTime = cartBannerData.content && (+cartBannerData.content.end_time * 1000)
      this.beginTime = cartBannerData.content && (+cartBannerData.content.start_time * 1000)
      cartBannerData.content && cartBannerData.content.content && cartBannerData.content.content.forEach(function (val) {
        _this.propData = _this.propData.concat(val)
        _this.aod_id = val.content.props.style.aod_id || '0'
      })
      // 获取当前时间
      this.currentTime = cartBannerData.zzz && cartBannerData.zzz * 1000 || Date.parse(new Date())
      // if(typeof window == "undefined") return
      if (this.propData) {
        this.$nextTick(function () {
          var bannerHeight = 0
          _this.propData.forEach(function (val) {
            if (val.content && val.content.props && val.content.props.items) {
              val.content.props.items.forEach(function (itemVal) {
                if (itemVal.height && itemVal.width) {
                  bannerHeight += (itemVal.height / itemVal.width * 10)
                }
              })
            }
          })
          if (bannerHeight) {
            this.bannerHeight = bannerHeight + 'rem'
          }
        })
      }

      this.propData && this.propData.forEach(function (data) {
        var items = data.content.props.items
        items.forEach(function (item) {
          var hrefType = item.hrefType
          var hrefTarget = item.hrefTarget
          var catUrl = item.catUrl
          // 对realOne, realTwo特殊处理一下
          if (hrefType === 'realOne' || hrefType === 'realTwo') {
            hrefType = 'real'
          }
          var type = hrefType + '_' + hrefTarget
          if (catUrl && hrefType && hrefTarget) {
            _this.$set(_this.itemCateType, type, catUrl)
          } else {
            _this.$set(_this.itemCateType, type, 'javascript:;')
          }
        })
      })
      /* 参数改成数组结构，以符合旧的结构 */
      if (cartBannerData.content && cartBannerData.content.content) {
        typeof window != 'undefined' && handleImageJump([{
          operations: cartBannerData.content.content
        }], _this)
      }
      this.resContent = cartBannerData.content
      // await this.setGaParams()
      // this.setSaParams()
      if(typeof window == 'undefined') return
      await this.initCcccAnalysis()
    },
    /**
     * 初始化埋点数据
     */
    async initCcccAnalysis () {
      if (this.resContent && this.resContent.content) { 
        try {
          if (typeof SaPageInfo == 'undefined' || !SaPageInfo.page_name) {
            const _saPageInfo = {
              page_id: 8,
              page_name: 'page_cart',
              page_param: {},
              start_time: new Date().getTime()
            }
            sa('set', 'setPageData', _saPageInfo)
          }
        } catch (e) { console.log(e) }
      }
    }
  }
  // })
}
</script>
<style lang="less">
.cart-empty-banner{
  background-color: #fff;
  margin: .2667rem 0;
}
</style>
